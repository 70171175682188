import { API_BASE_URL } from "../../config";
export function fetchAllCategoryProducts(CategoryID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}product_categories/${CategoryID}`
    );
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchAllSubCategoryProducts(SubCategoryID) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}product_subcategories/${SubCategoryID}`
    );
    const data = await response.json();
    resolve({ data });
  });
}

export function getCollection(collection_id) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      `${API_BASE_URL}collections-fetch/${collection_id}`
    );
    const data = await response.json();

    resolve({ data });
  });
}

export function getHomePageCollection(collection_id) {
    return new Promise(async (resolve) => {
      const response = await fetch(
        `${API_BASE_URL}collections-fetch/${collection_id}`
      );
      const data = await response.json();
  
      resolve({ data });
    });
  }
export function fetchAllSubCategories(CategoryID) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}subCategories/${CategoryID}`);
    const data = await response.json();
    resolve({ data });
  });
}
// export function fetchAllBrands() {
//     return new Promise(async (resolve) => {
//         const response = await fetch(`https://test.cyclecircle.one/api/v1/brand`);
//         const data = await response.json();
//         resolve({ data });
//     });
// }
export function fetchAllMaterials() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}material`);
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchThroughSearch(searchText) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}search/${searchText}`);
    const data = await response.json();
    resolve({ data });
  });
}
export function fetchThroughBrand(BrandName) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}brand/${BrandName}`);
    const data = await response.json();
    resolve({ data });
  });
}
