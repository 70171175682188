import React, { useEffect, useState } from "react";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import NoCartItem from "../Carts/NoCartItem";
import { IMAGE_BASE } from "../../config";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  fetchAllAdminUsersAsync,
  fetchAllAdminUsersOrdersAsync,
  selectAllAdminUser,
  selectAllAdminUserOrders,
} from "../../features/cartPage/cartPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectUser } from "../../features/auth/authSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "react-lazy-load-image-component/src/effects/blur.css";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedNumber } from "../../utils/numberAnimation";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import linkIcon from "../../assets/link-icon.svg";

function AdminOrders() {
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const users = useSelector(selectAllAdminUser);
  const userOrders = useSelector(selectAllAdminUserOrders);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openOrder, setOpenOrder] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(fetchAllAdminUsersAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  useEffect(() => {
    if (users) {
      setFilteredUsers(
        users.filter(
          (user) =>
            user?.FirstName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.LastName?.toLowerCase()?.includes(
              searchQuery?.toLowerCase()
            ) ||
            user?.PhoneNumber?.includes(searchQuery) ||
            user?.stateName
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase()) ||
            user?.business_name
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase())
        )
      );
    }
  }, [searchQuery, users]);

  useEffect(() => {
    // Sort the users based on the selected criteria
    if (filteredUsers) {
      let sortedUsers = [...filteredUsers];
      if (sortCriteria === "last_login") {
        sortedUsers.sort((a, b) => {
          const dateA = a?.LastLogin
            ? new Date(a.LastLogin)
            : new Date(8640000000000000);
          const dateB = b?.LastLogin
            ? new Date(b.LastLogin)
            : new Date(8640000000000000);
          return dateB - dateA;
        });
      } else if (sortCriteria === "order_count") {
        sortedUsers.sort((a, b) => b?.OrderCount - a?.OrderCount);
      } else if (sortCriteria === "state_name") {
        sortedUsers.sort((a, b) => {
          const stateA = a?.stateName?.toLowerCase() || "";
          const stateB = b?.stateName?.toLowerCase() || "";
          return stateA.localeCompare(stateB);
        });
      }
      setFilteredUsers(sortedUsers);
    }
  }, [sortCriteria]);

  const findAllOrderUsingUserID = (UserID) => {
    handleOpen();
    dispatch(fetchAllAdminUsersOrdersAsync(UserID)).then(() => {
      handleClose();
    });
  };

  const calculateTotalOrder = () => {
    let totalOrder = 0;
    for (const order of users) {
      totalOrder += order?.OrderCount;
    }
    return totalOrder;
  };

  const handleDownloadInvoice = async (id) => {
    try {
      // Construct the URL for downloading the PDF invoice
      const invoiceUrl = `https://cyclecircle.blr1.digitaloceanspaces.com/${IMAGE_BASE}_INVOICE/${
        id && id
      }.pdf`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = invoiceUrl;
      link.setAttribute("download", "invoice.pdf");

      // Simulate a click event to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  return (
    <div className="w-full h-screen relative overflow-auto">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 right-0 py-1 px-5
       shadow-md z-10 opacity-90 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Orders
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      <div className="w-full">
        <div className="w-full flex max-sm:flex-col items-center justify-center sm:justify-between">
          <div className="w-full sm:w-[200px] h-[90px] flex flex-col justify-between sm:mt-10 sm:ml-5 bg-white-1 rounded-md shadow-sm shadow-black-1/20 px-3 py-4">
            <span className="text-[16px] text-[#c1bebe]">Total Orders</span>
            <div className="w-full flex items-center justify-between">
              <span className="flex items-center text-[20px] text-green">
                {users && Array.isArray(users) ? calculateTotalOrder() : 0}
              </span>
              <Inventory2OutlinedIcon
                className="text-gray-300"
                style={{ fontSize: "24px" }}
              />
            </div>
          </div>
          <div className="w-full sm:w-auto max-sm:mt-5 sm:mr-5 flex max-sm:flex-col items-center max-sm:items-start justify-center sm:justify-end">
            <div className="w-full sm:w-[250px] h-[45px] sm:h-[35px] flex items-center bg-white-1 rounded-full shadow-sm shadow-black-1/20 px-2 sm:mr-5">
              <SearchIcon
                className="text-black-1/70"
                style={{ fontSize: "22px" }}
              />
              <input
                type="text"
                placeholder="Search by name, phone or state"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full h-full border-none outline-none p-0 pl-1 rounded-full text-[14px] tracking-wide text-black-1/80"
              />
            </div>
            <div className="flex items-center max-sm:mt-5 max-sm:ml-2">
              <span className="text-black-1/80 text-[14px] mr-2 font-bold tracking-wide">
                Sort by:
              </span>
              <FormControl
                variant="outlined"
                size="small"
                sx={{
                  minWidth: 120,
                  color: "black",
                  background: "white",
                  borderRadius: "4px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                }}
              >
                <Select
                  name="sort-by"
                  value={sortCriteria}
                  onChange={(e) => setSortCriteria(e.target.value)}
                  label="Sort by"
                  displayEmpty
                  sx={{
                    color: sortCriteria === "" ? "rgba(0, 0, 0, 0.6)" : "black", // Set color for empty value
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="last_login">Last Login</MenuItem>
                  <MenuItem value="order_count">Order Count</MenuItem>
                  <MenuItem value="state_name">State</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {filteredUsers && Array.isArray(filteredUsers) ? (
          <div className="w-full overflow-x-auto pt-[100px] sm:px-5">
            <div className="w-full flex items-center justify-between bg-white-1 rounded-t-md">
              <span className="text-[16px] text-black-1/80 tracking-wide p-3">
                Recent Orders
              </span>
            </div>
            <TableContainer component={Paper} className="shadow-md">
              <Table>
                <TableHead className="bg-gray-100">
                  <TableRow>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Last Login
                    </TableCell>

                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Phone Number
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Business Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      State Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Order Count
                    </TableCell>
                    <TableCell
                      align="center"
                      className="font-semibold"
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "700",
                        letterSpacing: "0.5px",
                      }}
                    >
                      <img
                        src={linkIcon}
                        alt="link-icon"
                        width={25}
                        height={25}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers &&
                    Array.isArray(filteredUsers) &&
                    filteredUsers?.map((user, index) => (
                      <>
                        <TableRow
                          key={index}
                          style={{
                            boxShadow:
                              user?.UserID === openOrder &&
                              "0 3px 3px 0 rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {user?.LastLogin !== null ? (
                              new Date(user?.LastLogin).toLocaleDateString(
                                "en-US",
                                {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )
                            ) : (
                              <b
                                style={{
                                  color: "var(--color-peach)",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                }}
                              >
                                Not Login Yet
                              </b>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {user?.FirstName}&nbsp;{user?.LastName}
                          </TableCell>
                          <TableCell align="center">
                            {user?.PhoneNumber}
                          </TableCell>
                          <TableCell align="center">
                            {user?.business_name || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {user?.stateName || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {user?.OrderCount}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              background:
                                openOrder === user?.UserID
                                  ? "linear-gradient(45deg, #283248 30%, #283220 90%)"
                                  : "linear-gradient(45deg, #28a447 30%, #4caf50 90%)",
                              color: "white",
                              cursor: "pointer",
                              letterSpacing: "1px",
                              transition: "background 0.3s ease-in-out",
                            }}
                            onClick={() => {
                              if (openOrder === user?.UserID) {
                                setOpenOrder(null);
                              } else {
                                setOpenOrder(user?.UserID);
                                findAllOrderUsingUserID(user?.UserID);
                              }
                            }}
                          >
                            {openOrder === user?.UserID ? "Close" : "Open"}
                          </TableCell>
                        </TableRow>
                        {openOrder === user?.UserID && (
                          <TableRow>
                            <TableCell colSpan={8} sx={{ padding: "0" }}>
                              <Table
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <TableHead sx={{ backgroundColor: "#f7f5f5" }}>
                                  <TableRow>
                                    <TableCell
                                      sx={{ width: "1/6", textAlign: "center" }}
                                    >
                                      Order ID
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "1/6", textAlign: "center" }}
                                    >
                                      Order Date
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "1/6", textAlign: "center" }}
                                    >
                                      Discount
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "1/6", textAlign: "center" }}
                                    >
                                      Amount
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "1/6", textAlign: "center" }}
                                    >
                                      Total Item Count
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "1/6", textAlign: "center" }}
                                    >
                                      Invoice
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "1/6", textAlign: "center" }}
                                    >
                                      ---
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {userOrders?.length > 0 &&
                                    userOrders
                                      ?.slice()
                                      .sort(
                                        (a, b) =>
                                          new Date(b?.OrderDate) -
                                          new Date(a?.OrderDate)
                                      )
                                      .map((orders, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {orders?.OrderNumber}
                                          </TableCell>
                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {orders?.OrderDate &&
                                              new Date(
                                                orders?.OrderDate
                                              ).toLocaleDateString("en-US", {
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric",
                                              })}
                                          </TableCell>
                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {orders?.DiscountAmount === null ? (
                                              <FormattedNumber value={0} />
                                            ) : (
                                              <FormattedNumber
                                                value={orders?.DiscountAmount}
                                              />
                                            )}
                                          </TableCell>
                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {orders?.Amount === null ? (
                                              <FormattedNumber value={0} />
                                            ) : (
                                              <FormattedNumber
                                                value={orders?.Amount}
                                              />
                                            )}
                                          </TableCell>
                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {orders?.TotalItemCount}
                                          </TableCell>
                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            <CloudDownloadIcon
                                              style={{
                                                fontSize: "25px",
                                                color: "var(--color-peach)",
                                                cursor: "pointer",
                                                marginLeft: "8px",
                                              }}
                                              onClick={() =>
                                                handleDownloadInvoice(
                                                  orders?.OrderNumber
                                                )
                                              }
                                            />
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            sx={{
                                              background:
                                                "linear-gradient(45deg, #ba4747 30%, #ff6b6b 90%)",
                                              color: "white",
                                              cursor: "pointer",
                                              letterSpacing: "1px",
                                              transition:
                                                "background 0.3s ease-in-out",
                                            }}
                                            onClick={() =>
                                              window.open(
                                                `/user/orders/view_order/${orders?.OrderNumber}`,
                                                "_blank"
                                              )
                                            }
                                          >
                                            View
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <NoCartItem text={"Pre-Book"} />
        )}
      </div>
    </div>
  );
}

export default AdminOrders;
