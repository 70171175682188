import { API_BASE_URL } from "../../config";
export function getCollections() {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}collections`);
    const data = await response.json();

    resolve({ data });
  });
}

export function createCollection(collectionData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}collections`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(collectionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editCollection(collectionData) {
  const collection_id = collectionData?.collection_id;
  const collection_name = collectionData?.collection_name;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}collections/${collection_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ collection_name }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function toggleCollection(collection_id) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}collections/${collection_id}`);
    const data = await response.json();

    resolve({ data });
  });
}

export function removeProductFromCollection(item_id) {
  return new Promise(async (resolve) => {
    const response = await fetch(`${API_BASE_URL}collections-items/${item_id}`);
    const data = await response.json();
    resolve({ data });
  });
}


export function fetchAllProductInventory() {
    return new Promise(async (resolve) => {
      const response = await fetch(`${API_BASE_URL}collection_product`);
      const data = await response.json();
  
      resolve({ data });
    });
  }

export function addProductInCollection(collectionData) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${API_BASE_URL}collections-items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(collectionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
