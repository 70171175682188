import { FormControl, InputLabel, MenuItem, Pagination, Select } from '@mui/material'
import React from 'react'

const CollectionPagination = ({filteredInventory,currentPage,handlePageSelectChange,rowsPerPage,handleChangePage}) => {
  return (
    <div className="max-md:w-[calc(100vw-12px)] max-md:-ml-3 sticky bottom-0 flex items-center justify-between mt-2 bg-white-1 shadow-sm p-4">
    {/* Page Selector */}
    <div className="max-md:hidden">
      <FormControl>
        <InputLabel id="page-select-label">Page</InputLabel>
        <Select
          labelId="page-select-label"
          value={currentPage}
          onChange={handlePageSelectChange}
          className="w-[70px] h-[35px] text-center"
          label="Page"
        >
          {Array.from({
            length: Math.ceil(filteredInventory?.length / rowsPerPage),
          }).map((_, index) => (
            <MenuItem key={index + 1} value={index + 1}>
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    {/* Pagination Controls */}
    <Pagination
      count={Math.ceil(filteredInventory?.length / rowsPerPage)}
      page={currentPage}
      onChange={handleChangePage}
      variant="outlined"
      shape="rounded"
      sx={{
        ".MuiPaginationItem-root": {
          color: "var(--color-green)",
          fontWeight: "bold",
        },
        ".MuiPaginationItem-root.Mui-selected": {
          backgroundColor: "var(--color-green)",
          color: "white",
        },
      }}
    />
  </div>
  )
}

export default CollectionPagination