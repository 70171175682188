import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import { IMAGE_BASE } from "../../config";
import CreateCollection from "../../dComponent/Collection/CreateCollection";
import {
  getCollectionsAsync,
  selectCollections,
  toggleCollectionAsync,
} from "../../features/admin/adminSlice";
import { Switch } from "@mui/material";
import {
  getCollectionAsync,
  resetCollectionData,
  selectAllCategoryProducts,
} from "../../features/listingPage/listingPageSlice";

const AdminCollections = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const userDetail = useSelector(selectUser);
  const collections = useSelector(selectCollections);
  const currentCollectionDetail = useSelector(selectAllCategoryProducts);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [currentCollectionId, setCurrentCollectionId] = useState(null);
  const [tab, setTab] = useState(null);

  useEffect(() => {
    if (userDetail?.UserID) {
      handleOpen();
      dispatch(getCollectionsAsync()).then(() => {
        handleClose();
      });
    }
  }, [dispatch, userDetail?.UserID]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleToggle = (id) => {
    handleOpen();
    dispatch(toggleCollectionAsync(id)).then(() => {
      dispatch(getCollectionsAsync());
      handleClose();
    });
  };

  const openCollection = (collection_id, collection_name) => {
    handleOpen();
    dispatch(getCollectionAsync(collection_id));
    setTab(`${collection_id}`);
    setCurrentCollection(collection_name);
    setCurrentCollectionId(collection_id);
    handleClose();
  };

  return (
    <div className="w-full min-h-screen bg-gray-50 relative flex flex-col">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="w-full h-14 bg-white-1 flex items-center justify-between sticky top-0 py-1 px-5
       shadow-md z-10 max-sm:hidden"
      >
        <span className="text-lg font-bold tracking-wider text-gray-800">
          Collections
        </span>
        <img
          loading="lazy"
          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
            userDetail?.UserImage || ""
          }`}
          alt={userDetail?.UserImage || ""}
          className="w-10 h-10 rounded-full border border-gray-300"
        />
      </div>
      {tab === null ? (
        <div className="w-full px-4 py-6 flex items-center flex-col md:flex-row justify-start flex-wrap">
          <div
            className="w-full md:w-[250px] h-[160px] border-[1.5px] rounded-md shadow-sm flex flex-col items-center justify-center mb-4 md:mr-4 cursor-pointer hover:border-blue"
            onClick={() => {
              setTab("create");
              dispatch(resetCollectionData());
            }}
          >
            <AddIcon
              className="text-black-1/90 my-1"
              style={{ fontSize: "25px" }}
            />
            <span className="text-[16px] text-black-1/90 my-1 font-semibold tracking-wide">
              Create a Collection
            </span>
          </div>
          {collections &&
            Array.isArray(collections) &&
            collections?.map((collection) => (
              <div
                className="w-full md:w-[250px] h-[160px] border-[1.5px] rounded-md shadow-sm flex flex-col items-start justify-between mb-4 md:mr-4 p-3 hover:border-blue"
                key={collection?.collections_Id}
              >
                <div className="w-full flex items-center justify-between">
                  <span className="text-[16px] text-black-1/90 tracking-wide font-semibold">
                    {collection?.collection_name}
                  </span>
                  <Switch
                    checked={collection?.Active === "true"}
                    onChange={() => {
                      handleToggle(collection?.collections_Id);
                    }}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "var(--color-green)", // Custom green for the thumb
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "var(--color-green)", // Custom green for the track
                        },
                    }}
                  />
                </div>
                <span
                  className="text-[15px] text-black-1/70 font-semibold tracking-wide cursor-pointer"
                  onClick={() =>
                    openCollection(
                      collection?.collections_Id,
                      collection?.collection_name
                    )
                  }
                >
                  View
                  <ChevronRightIcon
                    className="text-black-1/70"
                    style={{ fontSize: "25px" }}
                  />
                </span>
              </div>
            ))}
        </div>
      ) : tab === "create" ? (
        <CreateCollection
          setTab={setTab}
          userDetail={userDetail}
          dispatch={dispatch}
          handleOpen={handleOpen}
          handleClose={handleClose}
          currentCollection={currentCollection}
          setCurrentCollection={setCurrentCollection}
          currentCollectionId={currentCollectionId}
          setCurrentCollectionId={setCurrentCollectionId}
          currentCollectionDetail={currentCollectionDetail}
          openCollection={openCollection}
        />
      ) : (
        <CreateCollection
          setTab={setTab}
          userDetail={userDetail}
          dispatch={dispatch}
          handleOpen={handleOpen}
          handleClose={handleClose}
          currentCollection={currentCollection}
          setCurrentCollection={setCurrentCollection}
          currentCollectionId={currentCollectionId}
          setCurrentCollectionId={setCurrentCollectionId}
          currentCollectionDetail={currentCollectionDetail}
          openCollection={openCollection}
        />
      )}
    </div>
  );
};

export default AdminCollections;
