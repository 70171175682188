import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import premiumCollectionBanner from "../../assets/premium-collection-banner.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getCollectionsAsync,
  selectCollections,
} from "../../features/admin/adminSlice";
import { IMAGE_BASE } from "../../config";
import { useNavigate } from "react-router";
import { getCollectionAsync } from "../../features/listingPage/listingPageSlice";
import OneCollection from "../Collection/OneCollection";
import TwoCollection from "../Collection/TwoCollection";
import ThreeCollection from "../Collection/ThreeCollection";
import FourCollection from "../Collection/FourCollection";
import FiveCollection from "../Collection/FiveCollection";

const Collections = () => {
  const dispatch = useDispatch();
  const collections = useSelector(selectCollections);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCollectionsAsync());
  }, [dispatch]);

  const handleFetchCollectionProduct = (coll_id, coll_name) => {
    // dispatch(fetchAllCategoryProductsAsync(coll_id)).then(()=>{
    navigate(`/listing-page/${"coll" + coll_id}/${coll_name}`);
    // })
  };

  const activeCollections = collections?.filter(
    (collection) => collection?.Active === "true"
  );

  const renderCollectionComponent = (activeCollections) => {
    const count = activeCollections.length;

    switch (true) {
      case count === 1:
        return (
          <OneCollection
            collectionId={activeCollections[0]?.collections_Id}
            title={activeCollections[0]?.collection_name}
            dispatch={dispatch}
          />
        );
      case count === 2:
        return (
          <TwoCollection
            collectionId={activeCollections[0]?.collections_Id}
            title={activeCollections[0]?.collection_name}
            dispatch={dispatch}
            collections={activeCollections}
          />
        );
      case count === 3:
        return (
          <ThreeCollection
            collectionId={activeCollections[0]?.collections_Id}
            title={activeCollections[0]?.collection_name}
            dispatch={dispatch}
            collections={activeCollections}
          />
        );
      case count === 4:
        return (
          <FourCollection
            collectionId={activeCollections[0]?.collections_Id}
            title={activeCollections[0]?.collection_name}
            dispatch={dispatch}
            collections={activeCollections}
          />
        );
      case count >= 5:
        return (
          <FiveCollection
            collectionId={activeCollections[0]?.collections_Id}
            title={activeCollections[0]?.collection_name}
            dispatch={dispatch}
            collections={activeCollections}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {activeCollections && activeCollections.length > 0 && (
        <div className="flex flex-col lg:flex-col items-center lg:items-stretch justify-between md:py-4 lg:py-8 gap-6 px-0 md:px-8 lg:px-12 xl:px-28">
          {renderCollectionComponent(activeCollections)}
        </div>
      )}
    </>
  );
};

export default Collections;
