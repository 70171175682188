import React, { useState, useEffect } from "react";
import "./ProductsSlider.css";
import { connect } from "react-redux";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_BASE } from "../../../config";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { selectAllPromotion } from "../../../features/HomePage/homeSlice";
import {
  fetchAllWishlistsAsync,
  selectAllWishlists,
  addToWishlistsAsync,
  removeFromWishlistAsync,
} from "../../../features/wishlist/wishlistSlice";
import {
  fetchAllCartsAsync,
  removeFromCartsAsync,
} from "../../../features/cartPage/cartPageSlice";
import { selectUser } from "../../../features/auth/authSlice";
import {
  toggleOverlay,
  selectIsOverlayVisible,
} from "../../../store/slices/headerSlice";
import HoverSlider from "../../ListingPage/ListingProduct/HoverSlider";
import { useNavigate } from "react-router";
import SelectDesktopVariant from "../../EndPages/SelectDesktopVariant";
import cartOutlineLogo from "../../../img/navigation/cart-bookmark-outline.png";
import cartFilledLogo from "../../../img/navigation/cart-bookmark-filled.png";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  calculateDealerPrice,
  calculateDiscountPercentage,
  calculateOfferPrice,
  formatWithCommasWithDecimal,
} from "../../../utils/utils";

function ProductsSlider({ toggleOverlay, handleMouseEnter, handleMouseLeave }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pdts = useSelector(selectAllPromotion);
  const products = pdts?.Data ? pdts?.Data : pdts;
  const qtys = pdts?.quantity;
  const wishlist = useSelector(selectAllWishlists);
  const userDetail = useSelector(selectUser);
  const [openDesktopVariant, setOpenDesktopVariant] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (userDetail.UserID) {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID));
      dispatch(fetchAllCartsAsync(userDetail.UserID));
    }
  }, [dispatch, userDetail.UserID]);

  const handleLoginOverlay = (e) => {
    toggleOverlay();
    e.preventDefault();
  };

  // TO ADD PRODUCT INTO WISHLIST
  const handleAddToWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(addToWishlistsAsync(wishlistData)).then(() =>
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      })
    ); // Fetch updated wishlist
    e.preventDefault();
  };
  const handleRemoveFromWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(removeFromWishlistAsync(wishlistData)).then(() => {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      });
    });
    e.preventDefault();
  };

  // REMOVE FROM CART
  const removeFromCart = (data, e) => {
    e.preventDefault();
    let cartData = {
      userID: userDetail?.UserID,
      productId: data?.prod_ID,
    };
    dispatch(removeFromCartsAsync(cartData));
    dispatch(fetchAllCartsAsync(userDetail.UserID));
  };

  // Function to check if a product is in the wishlist
  const isInWishlist = (productId) => {
    return (
      Array.isArray(wishlist && wishlist?.product) &&
      wishlist?.product?.some((item) => item.prod_ID === productId)
    );
  };

  const isOutOfStock = (productId) => {
    const { CCQuantity } = qtys; // Replace `inventory` with your actual object name

    // Flatten the nested arrays into a single array of product objects
    const allProducts = CCQuantity?.flat();

    // Filter products matching the given productId
    const matchingProducts = allProducts?.filter(
      (product) => product?.prod_ID === productId
    );

    // If no matching products are found, return true (considered out of stock)
    if (matchingProducts?.length === 0) {
      return true;
    }

    // Check if all matching products have qty of 0
    return matchingProducts?.every((product) => product?.qty === 0);
  };

  const handleNavigate = (prod_id, event) => {
    event.preventDefault();
    navigate(`/product/${prod_id}`);
  };

  const handleOpenDesktopVariant = (id, e) => {
    setOpenDesktopVariant(id);
    e.preventDefault();
  };

  return (
    <div className="products-slider-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="products-slider-main-container">
        {(products?.product || []).map((data) => (
          <div
            className="products-slider-card-container"
            key={data?.prod_ID}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {data?.Badges && (
              <div className="products-slider-card-product-badges-container">
                <img
                  loading="lazy"
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Logo/${
                    data?.Badges && data?.Badges
                  }`}
                  alt=""
                  className="product-slider-card-product-badges"
                />
              </div>
            )}
            {userDetail?.UserID === 1 && (
              <span
                className="products-list-card-product-badges-container"
                style={{
                  padding: "3px",
                  fontSize: "12px",
                  letterSpacing: "0.3px",
                }}
              >
                {/* {data?.prod_ID?.startsWith("9") ? (
                  <span
                    style={{
                      background: "var(--color-peach)",
                      color: "white",
                    }}
                  >
                    CC EXCLUSIVE
                  </span>
                ) : (
                  <span
                    style={{
                      background: "var(--color-green)",
                      color: "white",
                    }}
                  >
                    CC INCLUSIVE
                  </span>
                )} */}
              </span>
            )}
            {data?.manufacture_name !== "ESI" && (
              <div className="products-slider-card-favourite-container">
                {isInWishlist(data.prod_ID) ? (
                  <img
                    loading="lazy"
                    src={cartFilledLogo}
                    alt=""
                    onClick={(e) => {
                      handleRemoveFromWishlist(data, e);
                    }}
                    id="product-slider-card-favourite-svg"
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={cartOutlineLogo}
                    alt=""
                    onClick={(e) => {
                      userDetail && userDetail?.UserID
                        ? handleAddToWishlist(data, e)
                        : handleLoginOverlay(e);
                    }}
                    id="product-slider-card-favourite-svg"
                  />
                )}
              </div>
            )}
            <div
              className="products-slider-card-image-container"
              onClick={(event) => handleNavigate(data.prod_ID, event)}
            >
              <HoverSlider images={data?.images && data.images} />
            </div>
            <div className="products-slider-card-product-details">
              <div className="products-slider-card-product-ratings-container">
                <span className="products-slider-card-product-rating">
                  {parseInt(data.net_rating).toFixed(1)}
                </span>
                <StarIcon id="product-slider-card-star-svg" />
                <span className="products-slider-card-product-rating-divider">
                  |
                </span>
                <span className="products-slider-card-product-no-of-rating">
                  313
                </span>
              </div>
              <div className="products-slider-card-product-cart-container">
                {isOutOfStock(data.prod_ID) ? (
                  <span className="text-peach text-[14px] font-bold">Out of Stock</span>
                ) : (
                  <ShoppingCartOutlinedIcon
                    onClick={(e) => {
                      userDetail && userDetail?.UserID
                        ? handleOpenDesktopVariant(data.prod_ID, e)
                        : handleLoginOverlay(e);
                    }}
                    id="product-slider-card-cart-svg"
                  />
                )}
              </div>
              {data && data?.manufacture_images ? (
                <img
                  loading="lazy"
                  className="products-slider-card-product-brand-img"
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                    data && data?.manufacture_images
                  }`}
                  alt=""
                />
              ) : (
                <span className="products-slider-card-product-brand-name">
                  {data.manufacture_name && data.manufacture_name}
                </span>
              )}
              <div className="products-slider-card-product-name">
                <span className="products-slider-card-product-name-span">
                  {data.prod_name && data.prod_name.slice(0, 45)}
                  {data.prod_name.length > 45 && "..."}
                </span>
              </div>
              <div className="products-slider-card-product-price-container">
                <div className="products-slider-card-product-current-price">
                  {data &&
                    data?.skuDetails &&
                    data?.skuDetails?.length > 0 &&
                    data?.skuDetails[0]?.NDP_Price && (
                      <>
                        <span className="products-slider-card-product-current-price-span">
                          Dealer Price -{" "}
                          {Object.keys(userDetail).length > 0 ? (
                            <b
                              style={{
                                textDecoration:
                                  data?.skuDetails[0].Offer_price !== null &&
                                  "line-through",
                                fontSize:
                                  data?.skuDetails[0].Offer_price !== null &&
                                  "12px",
                              }}
                            >
                              ₹
                              {formatWithCommasWithDecimal(
                                calculateDealerPrice(
                                  parseInt(data.skuDetails[0].MRP_price),
                                  parseInt(data.skuDetails[0].NDP_Price),
                                  data?.GST
                                )
                              )}
                            </b>
                          ) : (
                            <b
                              className="login-to-see-price"
                              onClick={toggleOverlay}
                            >
                              Login to see Price
                            </b>
                          )}
                        </span>
                        {Object.keys(userDetail).length > 0 &&
                          data?.skuDetails[0].Offer_price !== null && (
                            <span className="products-slider-card-product-offer-price">
                              ₹
                              {formatWithCommasWithDecimal(
                                calculateOfferPrice(
                                  parseInt(data.skuDetails[0].MRP_price),
                                  parseInt(data.skuDetails[0].Offer_price),
                                  data?.GST
                                )
                              )}
                            </span>
                          )}
                      </>
                    )}
                </div>
                <div className="products-slider-card-product-original-price">
                  {data &&
                    data?.skuDetails &&
                    data?.skuDetails?.length > 0 &&
                    data?.skuDetails[0]?.MRP_price && (
                      <span className="products-slider-card-product-original-price-span">
                        Product MRP - ₹
                        {formatWithCommasWithDecimal(
                          parseInt(
                            data.skuDetails[0] && data.skuDetails[0].MRP_price
                          )
                        )}
                      </span>
                    )}
                </div>
                {calculateDiscountPercentage(
                  data?.skuDetails[0]?.MRP_price,
                  data?.skuDetails[0]?.NDP_Price
                ) > 0 && (
                  <div className="product-slider-card-product-dealer-margin-ribbon">
                    {Object.keys(userDetail).length > 0 ? (
                      <>
                        <LocalOfferOutlinedIcon />{" "}
                        <span
                          style={{
                            textDecoration:
                              data?.skuDetails[0].Offer_price !== null &&
                              "line-through",
                            fontSize:
                              data?.skuDetails[0].Offer_price !== null && "8px",
                            margin: "0 0.2vw",
                          }}
                        >
                          {" "}
                          {calculateDiscountPercentage(
                            data?.skuDetails[0]?.MRP_price,
                            calculateDealerPrice(
                              parseInt(data.skuDetails[0].MRP_price),
                              parseInt(data.skuDetails[0].NDP_Price),
                              data?.GST
                            )
                          )}
                          %
                        </span>
                        {data?.skuDetails[0].Offer_price !== null && (
                          <span className="product-slider-card-product-dealer-margin-offer-ribbon">
                            {calculateDiscountPercentage(
                              data?.skuDetails[0]?.MRP_price,
                              calculateOfferPrice(
                                parseInt(data.skuDetails[0].MRP_price),
                                parseInt(data.skuDetails[0].Offer_price),
                                data?.GST
                              )
                            )}
                            %
                          </span>
                        )}{" "}
                      </>
                    ) : (
                      <div className="not-visible-container">
                        <VisibilityOffOutlinedIcon onClick={toggleOverlay} />{" "}
                        <span>%</span>
                      </div>
                    )}{" "}
                    DEALER MARGIN
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {openDesktopVariant !== null && (
        <SelectDesktopVariant
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          openDesktopVariant={openDesktopVariant}
          setOpenDesktopVariant={setOpenDesktopVariant}
          product={products?.product.find(
            (item) => item.prod_ID === openDesktopVariant
          )}
          qtys={qtys}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});

const mapDispatchToProps = {
  toggleOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSlider);
