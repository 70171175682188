import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import {
  createCollectionAsync,
  editCollectionAsync,
  selectCollectionId,
} from "../../features/admin/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { createCollection } from "../../features/admin/adminAPI";

const CollectionName = ({
  userDetail,
  handleOpen,
  handleClose,
  currentCollection,
  setCurrentCollection,
  currentCollectionId,
  setCurrentCollectionId,
  openCollection,
}) => {
  const dispatch = useDispatch();
  const [collectionId, setCollectionId] = useState(null);
  const [collectionName, setCollectionName] = useState(null);

  const handleSave = async () => {
    if (!collectionName.trim()) {
      toast("Collection name cannot be empty!");
      return;
    }

    handleOpen();
    try {
      if (collectionId || currentCollectionId) {
        let collectionData = {
          collection_id: collectionId ? collectionId : currentCollectionId,
          collection_name: collectionName,
        };
        await dispatch(editCollectionAsync(collectionData)).unwrap();
        toast("Collection name updated successfully!");
        setCurrentCollection(collectionName);
      } else {
        let collectionData = {
          collection_name: collectionName,
        };
        const response = await createCollection(collectionData);
        if (response?.collectionId) {
          toast.success(`${response?.message}`);
          setCurrentCollection(collectionName);
          setCurrentCollectionId(response?.collectionId);
          setCollectionId(response?.collectionId);
          openCollection(response?.collectionId, collectionName);
        } else {
          toast.error("Failed to create the collection. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast("An error occurred while saving the collection. Please try again.");
    } finally {
      handleClose();
    }
  };

  return (
    <div className="mb-6">
      <h3 className="text-lg font-bold text-gray-700 mb-2">Collection Name</h3>
      <div className="flex flex-wrap gap-4 w-full lg:w-[400px]">
        <TextField
          variant="outlined"
          value={collectionName !== null ? collectionName : currentCollection}
          onChange={(e) => {
            const value = e.target.value;
            setCollectionName(value);
          }}
          placeholder="Enter the collection name"
          className="flex-1"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              fontWeight: "600",
              background: "white",
              fontSize: "14px",
              fontFamily: "inherit",
              letterSpacing: "1px",
              height: "40px",
            },
            "& .MuiOutlinedInput-input": {
              padding: "4px 8px",
            },
            "& .MuiInputLabel-root": {
              fontSize: "14px",
              fontFamily: "inherit",
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{
            height: "40px",
            fontSize: "15px",
            letterSpacing: "2px",
            fontFamily: "inherit",
            fontWeight: "600",
            backgroundColor: "var(--color-green)",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "var(--color-green-hover)",
            },
          }}
          onClick={handleSave}
        >
          {collectionId || currentCollectionId ? "Edit" : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default CollectionName;
