import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCollections,
  editCollection,
  createCollection,
  addProductInCollection,
  toggleCollection,
  removeProductFromCollection,
  fetchAllProductInventory,
} from "./adminAPI";

const initialState = {
  collectionName: [],
  collectionData: [],
  collectionProduct: [],
  collectionId: null,
  status: "idle",
};

// Async thunks for order-related actions
export const getCollectionsAsync = createAsyncThunk(
  "admin/getCollections",
  async () => {
    try {
      const response = await getCollections();
      return response?.data;
    } catch (error) {
      throw Error("Failed to get collection");
    }
  }
);

export const fetchAllProductInventoryAsync = createAsyncThunk(
  "admin/fetchAllProductInventory",
  async () => {
    try {
      const response = await fetchAllProductInventory();
      return response?.data;
    } catch (error) {
      throw Error("Failed to get collection");
    }
  }
);

export const removeProductFromCollectionAsync = createAsyncThunk(
  "admin/removeProductFromCollection",
  async (item_id) => {
    try {
      const response = await removeProductFromCollection(item_id);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get collection");
    }
  }
);

export const toggleCollectionAsync = createAsyncThunk(
  "admin/toggleCollection",
  async (collection_id) => {
    try {
      const response = await toggleCollection(collection_id);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get collection");
    }
  }
);

export const editCollectionAsync = createAsyncThunk(
  "admin/editCollection",
  async (collectionData) => {
    try {
      const response = await editCollection(collectionData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get inventory");
    }
  }
);

export const createCollectionAsync = createAsyncThunk(
  "admin/createCollection",
  async (collectionData) => {
    try {
      const response = await createCollection(collectionData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get all inventory");
    }
  }
);

export const addProductInCollectionAsync = createAsyncThunk(
  "admin/addProductInCollection",
  async (collectionData) => {
    try {
      const response = await addProductInCollection(collectionData);
      return response?.data;
    } catch (error) {
      throw Error("Failed to get all inventory");
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetCollectionId: (state) => {
      state.collectionId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCollectionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionName = action.payload;
      })
      .addCase(fetchAllProductInventoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllProductInventoryAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionProduct = action.payload;
      })
      .addCase(toggleCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(removeProductFromCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeProductFromCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(createCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionId = action.payload;
      })
      .addCase(addProductInCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProductInCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(editCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const selectCollections = (state) => state.admin.collectionName;
export const selectCollectionId = (state) => state.admin.collectionId;
export const selectAllCollectionProduct = (state) =>
  state.admin.collectionProduct;

export const { resetCollectionId } = adminSlice.actions;

export default adminSlice.reducer;
