import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { IMAGE_BASE } from "../../config";
import {
  calculateDealerPrice,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { useDispatch } from "react-redux";
import {
  removeProductFromCollectionAsync,
} from "../../features/admin/adminSlice";
import { getCollectionAsync } from "../../features/listingPage/listingPageSlice";

const SelectedItems = ({ product }) => {
  const dispatch = useDispatch();

  const removeProduct = (prod) => {
    dispatch(removeProductFromCollectionAsync(prod?.collections_item_Id)).then(
      () => {
        dispatch(getCollectionAsync(prod?.collections_Id));
      }
    );
  };
  return (
    <div className="mb-6">
      {product && product?.length > 0 && (
        <h4 className="text-lg font-bold text-gray-700 mb-2">
          Selected Items ({product && product?.length})
        </h4>
      )}
      <div className="flex flex-wrap gap-3 w-full p-2">
        {product &&
          product?.map((prod) => (
            <div className="w-[150px] h-[180px] relative p-2 flex flex-col bg-white-1 shadow-md">
              <CancelIcon
                onClick={() => removeProduct(prod)}
                className="text-peach absolute -top-[8px] -right-[8px] cursor-pointer"
              />
              <img
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                  prod?.images && prod?.images[0]
                }`}
                alt="bike-img"
                className="h-1/2 w-full object-contain bg-gray-50 rounded-md"
              />
              <div className="flex flex-col items-start justify-start pt-[8px]">
                <img
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                    prod && prod?.manufacture_images
                  }`}
                  alt="brand-img"
                  className="h-[15px] object-contain"
                />
                <span className="text-black-1/70 text-[12px] tracking-wide h-[30px] my-1 w-full">
                  {(prod?.prod_name).slice(0, 28)}
                </span>
                <span className="text-black-1 font-bold text-[14px] tracking-wide w-full">
                  ₹
                  {formatWithCommasWithDecimal(
                    calculateDealerPrice(
                      parseInt(prod?.skuDetails[0]?.MRP_price),
                      parseInt(prod?.skuDetails[0]?.NDP_Price),
                      prod?.GST
                    )
                  )}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SelectedItems;
